/* about dialog */
.about-mdl-main {
  width: 450px;
  height: 282px;
}

.about-mdl-header {
  border-bottom: none;
}

.about-mdl-main .modal-content {
  border-radius: 0;
}

.about-mdl-logo {
  width: 76px;
  height: 60px;
  float: left;
}

.about-mdl-content-title {
  color: #333;
  font-weight: bold;
  font-size: 35px;
  float: left;
  margin-top: 8px;
  margin-bottom: 15px;
  margin-left: 9px;
}

.about-mdl-content-vrs {
  font-size: 14px;
  margin-bottom: 5px;
  text-align: center;
}

.about-mdl-main .modal-content {
  height: auto;
}
