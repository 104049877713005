/* Tab bar */

.tab-bar {
    margin-bottom: -2px;
}

.tab-bar.loading {
    height: 34px;
}

.tab-bar button {
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    color: #555;
    display: block;
}

.tab-bar button.glyphicon {
    position: initial;
    line-height: initial;
    margin-bottom: 4px;
}

.tab-bar button.glyphicon.active {
    padding-bottom: 9px;
    margin-bottom: 0;
}

.tab-bar button:hover{
    color: #333333;
}

.tab-bar button.active {
    color: #4285F4;
    border-bottom: 3px solid #4285F4;
}

.tab-bar button.active:not(.fi) {
    font-weight: 700;
    /*padding-bottom: 5px;*/
}

.tab-bar button.fi {
    margin-right: 0;
}

.tab-bar button.fi {
    font-size: 1rem;
    margin-right: unset;
    padding-bottom: unset;
    /* hack */
    margin-bottom: -4px;
}

.tab-bar li:has(.fi) {
    margin-right: 0;
    vertical-align: sub;
}

.tab-bar-menu{
    margin-bottom: -12px;
}

.tab-bar-menu-active .dropdown-toggle, .results-filter .dropdown-toggle {
    color: #4285F4 !important;
}

.tab-bar-menu-active button.active{
    border-bottom: none !important;
}

.tab-bar-menu button{
    box-shadow: none;
}

.tab-bar-menu li{
    width: 100%;
}

.tab-bar-menu li:hover{
    background: none;
}

.tab-progress {
    height: 1px;
}
