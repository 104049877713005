/* collapsible list */
.collapse-toggler{
    color: #9e9e9e;
    margin-left: 10px;
}
.collapse-toggler.bottom {
    margin: 5px 0 5px 10px;
}

.collapse-toggler:hover:not(:has(.glyphicon)){
    cursor: pointer;
    text-decoration: underline;
}

.collapse-visible-part{
    margin: 0;
    word-wrap: break-word;
}

.coll-text.collapse.in{
    display: inline;
}

.coll-text-tgl{
    color: #9e9e9e;
}

.coll-text-tgl:hover{
    cursor: pointer;
    text-decoration: underline;
}

.coll-text-tgl.glyphicon:hover{
    cursor: pointer;
    color: #4e4e4e;
    text-decoration: none;
}

.collapse-hidden-in {
    margin: 4px 0 0 0;
    border-radius: 2px;
}

.collapse li {
    word-break: break-all;
}

.collapsible-scroller {
    /*height: 150px;*/
    overflow-y: auto;
    max-height: 150px;
}
