:root {
    --blob-size: 20;
}

.blob {
    border: none;
}

.blob.glyphicon:before {
    cursor: default;
    pointer-events: none;
    color: rgb(45, 186, 59, 1);
    background-color: rgba(45, 186, 59, 1);
    box-shadow: 0 0 0 0 rgba(45, 186, 59, 1);

    display: inline-block;
    font-size: 0.8rem;
    flex: 0 0 !important;
    border-radius: 50%;
    margin: var(--margin);

    box-sizing: border-box;
    transform: scale(1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(45, 186, 59, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(45, 186, 59, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(45, 186, 59, 0);
    }
}
