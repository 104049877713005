/* summary panel */
.summary-panel {
  margin-top: 10px;
  width: 300px;
  margin-left: 130px;
  border-color: #ebebeb;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.summary-title {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-left: 10px;
  /*word-wrap: break-word;*/
  word-wrap: normal;
  margin-top: 15px;
}

.img-panel {
  box-shadow: none;
  text-align: center;
}

/* accordion - general styles*/
.accordion-padding {
  margin-top: 20px;
  margin-left: 15px;
}

.query-results-accordion {
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.summary-header {
  background-color: #eaeaea;
  padding: 5px;
}

.summary-header span {
  font-size: 18px;
}

.summary-header input {
  font-size: 12px;
  padding: 2px 5px;
  /*font-style: italic;*/
  margin: 5px 0;
  width: 100%;
  border: 1px dotted #aaaaaa;
}

.summary-header .input-reset {
  font-size: 12px;
}

.query-results-accordion .accordion__item,
.query-results-accordion .accordion__title {
  background-color: #fff;
}

.summary-item-highlight {
  color: #fff;
  background-color: #90c3f7;
  border-radius: 3px;
  padding: 0 2px;
}

mark, .search-res-item-highlight {
  color: blueviolet;
  /*color: #3a3a3a;*/
  background-color: #eaeaea;
  border-radius: 3px;
  padding: 0; /* 2px;*/
}

.selected mark, .selected .search-res-item-highlight {
  color: #eaeaea;
  background-color: #8a8a8a;
}

.autosuggest .focused mark {
  background-color: rgba(0,0,0,0);
}

.summary-body {
  max-height: 345px;
  overflow: auto;
}

.summary-body-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.summary-body-list li {
  border-bottom: 1px solid #eaeaea;
  padding: 4px 12px;
  word-break: break-word;
}

.summary-body-list li.selected {
  background-color: #e5e5e5;
}

.summary-body-list li:last-child {
  border-bottom: none;
}

.summary-body-list li:hover {
  background-color: #ebf5ff;
  cursor: pointer;
}

.query-details {
  border-bottom: 2px solid #eaeaea;
  word-break: break-word;
  padding: 7px;
  .labeled-entity {
    width: 100%;
  }
}

.query-details > div {
  display: flex;
  flex-direction: column;
}

.query-details img,
.query-details object {
  /*margin-left: 20%;*/
  margin: auto 0;
  width: 95%
}

.query-details p {
  padding: 7px;
}

.query-details > p {
  margin: 0;
}

.query-details-body {
  padding: 2px 9px;
}

.query-details .collapse {
  max-height: 150px;
  overflow: auto;
}

.query-details span > strong {
  font-size: 15px;
  font-weight: 700;
  /*word-break: break-all;*/
  word-break: normal;
}

.query-details .srch-rst-desc span {
  word-break: break-word;
}
