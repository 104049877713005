@keyframes lds-ellipsis3 {
  0%, 25% {
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: calc(var(--loader-size) * 68 / 32); /* 100px */
  }
  100% {
    left: calc(var(--loader-size) * 136 / 32); /* 168px */
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0%, 25% {
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: calc(var(--loader-size) * 68 / 32);
  }
  100% {
    left: calc(var(--loader-size) * 136 / 32);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis {
  0% {
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: calc(var(--loader-size) * 68 / 32);
  }
  75% {
    left: calc(var(--loader-size) * 136 / 32);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: calc(var(--loader-size) * 136 / 32);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis {
  0% {
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: calc(var(--loader-size) * 68 / 32);
  }
  75% {
    left: calc(var(--loader-size) * 136 / 32);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: calc(var(--loader-size) * 136 / 32);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.lds-ellipsis > div {
  /* Need position: relative to make the dots respect the ellipsis margin */
  /* Need Y transform to keep them top-aligned */
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  -webkit-animation: lds-ellipsis var(--loader-timing) cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis var(--loader-timing) cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis > div:nth-child(1) {
  -webkit-animation-delay: calc(var(--loader-timing) / -2);
  animation-delay: calc(var(--loader-timing) / -2);
  background: var(--plex-yellow);
}
.lds-ellipsis > div:nth-child(2) {
  -webkit-animation-delay: calc(var(--loader-timing) / -4);
  animation-delay: calc(var(--loader-timing) / -4);
  background: var(--plex-orange);
}
.lds-ellipsis > div:nth-child(3) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background: var(--plex-red);
}
.lds-ellipsis > div:nth-child(4) {
  -webkit-animation: lds-ellipsis3 var(--loader-timing) cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis3 var(--loader-timing) cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: var(--plex-black);
}
.loading-animation {
  margin: auto;
  width: 100%;
  height: 100%;
}

.lds-ellipsis {
  margin: auto;
  /* position: relative required to keep dots positioned properly */
  position: relative;
  height: var(--loader-size);
  width: calc(var(--loader-size) * 6);
}

.sliding-dot {
  color: white;
}
