/* search result*/

.srch-rst-row {
    width: 50%; /* hack to prevent width expansion beyond parent */
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    line-height: normal;
}

.search-result-image {
    width: 100px;
    height: 100px;
    display: block;
}

.image-hover {
    max-width: 300px;
}

.search-result-image-popover {
    width: 275px;
    height: 275px;
    padding: 8px;
}

.grid-viewport-row:last-child .grid-view-row {
    padding-bottom: 20px;
}

.grid-view-row {
    display: flex;
    flex-direction: row;
    /*align-items: stretch;*/
    /*width: 100%;*/
}

.grid-view-row .collapsible-scroller {
    width: 480px;
    overflow-x: hidden;
}

.search-result {
    display: flex;
    flex-direction: column;
}

.srch-rst-img{
    margin-right: 20px;
}

.popover .compound-structure-image {
    margin: 4px;
}

.srch-rst-img .compound-structure-image {
    width: 128px;
    height: 128px;
}

.srch-rst-desc, div.srch-rst-desc {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    word-wrap: break-word;
    font-size: 14px;
    color: #545454;
    margin-top: 2px;
    margin-bottom: 2px;
    .collapsible-collapsible {
        width: 100%;
    }
    .collapse {
        overflow-y: auto;
        max-height: 150px;
    }
}

.srch-rst-header{
    font-weight: 400;
    font-size: 20px;
}

.srch-rst-links{
    clear: both;
    margin-top: 3px;
}

.srch-rst-link .search-icon {
    margin-right: 3px;
    display: block;
    float: left;
    margin-top: 3px;
}

.srch-rst-header a, .srch-rst-header a:hover{
    text-decoration: none;
}

.srch-rst-title{
    color: #3126B4;
    word-break: break-word;
    cursor: pointer;
}

.srch-rst-link{
    color: #3126B4;
    /*font-size: 14px;*/
    font-weight: 400;
    margin-right: 15px;
    border: none;
    background: none;
    padding: 0;
}

.srch-rst-link-disabled, button.srch-rst-link-disabled {
    /*color: #777;*/
    opacity: var(--disabled-opacity);
    cursor: default;
}

.srch-rst-link:focus{
    outline: 0;
}

.srch-rst-total{
    font-size: 15px;
    color: #808080;
}

.srch-rst-source{
    color: #1A7671;
    font-size: 16px;
    margin-right: 10px;
}

.srch-rst-source.dropdown {
    margin-top: -2px;
}

.srch-rst-b-title, .block-title{
    color: #777;
    font-weight: bold;
    white-space: nowrap;
}

.source-links, .source-links .show {
    .btn.dropdown-toggle,
    .btn.dropdown-toggle:active,
    .btn.dropdown-toggle.active {
        color: #1A7671 !important;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-shadow: none;
    }
}

.srch-rst-link-group:hover, .srch-rst-link-group:active, .srch-rst-link-group:focus{
    background: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: #1A7671;
}

.srch-rst-link-group .dropdown-menu {
    border-radius: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
}

.srch-new-search-link {
    color: #3126B4;
    font-size: 14px;
}

.srch-new-search-link:hover{
    text-decoration: none;
    color: #3126B4;
}

.srch-new-search-link span{
    top: 3px;
}

.srch-new-search-link span:last-child {
    margin-left: 0.25rem;
}

.image-hover{
    left: 32px !important;
}

.source-links {
  display: inline-flex;
  flex-wrap: wrap;
}

.source-links a {
    text-decoration: none;
}

.search-item-footer a {
    text-decoration: none;
}

