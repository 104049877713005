.result-stub {
  text-align: center;
  padding: 42% 5px !important;
  height: 400px;
}

.result-wrapper {
  border-radius: 3px;
  border: 1px solid #e4e4e4;
}

.result-output {
  padding: 10px !important;
  word-break: break-word;
}

.clear-margin {
  margin: 0 !important;
}

.float-right {
  float: right;
}

.error-message {
  color: red;
}

.example-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.dropup, .input-element {
  margin-right: 5px;
}

.sandbox button.copy-to-clipboard {
  margin-left: var(--margin);
  line-height: inherit;
  top: initial;
}

.sandbox {
  .cm-scroller, textarea, .result-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }
}

.sandbox-tab {
  border-left: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}

.footer {
  margin: 15px 5px;
  border-top: 1px solid #d0d0d0;
  padding-top: 20px;
}
