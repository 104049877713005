.ball {
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    max-height: 100%;
    margin: auto;
}

.ball div {
    background-color: #aeaeae;
    border-radius: 200px;
    border: 1px solid #505050;
}

.ball span {
    display: inline-block;
    color: #fff;
    word-break: break-word;
    position: relative;
    margin: auto auto;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    text-transform: uppercase;
}

.org-logo {
    max-width: 50px;
    max-height: 50px;
    margin: 10px 10px 0 0;
    position: absolute;
    top: 0;
    right: 0;
}

div.org-logo {
    width: 50px;
    height: 50px;
}

img.ball-lg {
    max-width: 165px;
    max-height: 165px;
}
.ball-lg div {
    width: 165px;
    height: 165px;
    margin: 22px auto 11px auto;
}

.ball-lg span{
    font-size: 18px;
}

img.ball-md {
    max-width: 120px;
    max-height: 120px;
}

.ball-md div {
    width: 120px;
    height: 120px;
    margin: 21px 10px 8px 10px;
}

.ball-md span{
    font-size: 15px;
}

img.ball-sm {
    max-width: 80px;
    max-height: 80px;
}
.ball-sm div {
    width: 80px;
    height: 80px;
    margin: 13px 10px 8px 10px;
}

.ball-sm span{
    font-size: 10px;
}

.logo-image {
    max-width: 165px;
    max-height: 165px;
}
