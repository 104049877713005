@keyframes lds-spin {
    0% {
      opacity: 1;
      -webkit-transform: scale(1.5, 1.5);
      transform: scale(1.5, 1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes lds-spin {
    0% {
      opacity: 1;
      -webkit-transform: scale(1.5, 1.5);
      transform: scale(1.5, 1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  .lds-spin {
    position: relative;
  }
  .lds-spin div > div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #757474;
    -webkit-animation: lds-spin 1.1s linear infinite;
    animation: lds-spin 1.1s linear infinite;
  }
  .lds-spin div:nth-child(1) > div {
    left: 152px;
    top: 92px;
    -webkit-animation-delay: -0.99s;
    animation-delay: -0.99s;
  }
  .lds-spin > div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 160px 100px;
    transform-origin: 160px 100px;
  }
  .lds-spin div:nth-child(2) > div {
    left: 140.54101964px;
    top: 127.26711512px;
    -webkit-animation-delay: -0.88s;
    animation-delay: -0.88s;
  }
  .lds-spin > div:nth-child(2) {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
    -webkit-transform-origin: 148.54101964px 135.26711512px;
    transform-origin: 148.54101964px 135.26711512px;
  }
  .lds-spin div:nth-child(3) > div {
    left: 110.54101964px;
    top: 149.06339096px;
    -webkit-animation-delay: -0.77s;
    animation-delay: -0.77s;
  }
  .lds-spin > div:nth-child(3) {
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
    -webkit-transform-origin: 118.54101964px 157.06339096px;
    transform-origin: 118.54101964px 157.06339096px;
  }
  .lds-spin div:nth-child(4) > div {
    left: 73.45898036px;
    top: 149.06339096px;
    -webkit-animation-delay: -0.66s;
    animation-delay: -0.66s;
  }
  .lds-spin > div:nth-child(4) {
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
    -webkit-transform-origin: 81.45898036px 157.06339096px;
    transform-origin: 81.45898036px 157.06339096px;
  }
  .lds-spin div:nth-child(5) > div {
    left: 43.45898036px;
    top: 127.26711512px;
    -webkit-animation-delay: -0.55s;
    animation-delay: -0.55s;
  }
  .lds-spin > div:nth-child(5) {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
    -webkit-transform-origin: 51.45898036px 135.26711512px;
    transform-origin: 51.45898036px 135.26711512px;
  }
  .lds-spin div:nth-child(6) > div {
    left: 32px;
    top: 92px;
    -webkit-animation-delay: -0.44s;
    animation-delay: -0.44s;
  }
  .lds-spin > div:nth-child(6) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform-origin: 40px 100px;
    transform-origin: 40px 100px;
  }
  .lds-spin div:nth-child(7) > div {
    left: 43.45898036px;
    top: 56.73288488px;
    -webkit-animation-delay: -0.33s;
    animation-delay: -0.33s;
  }
  .lds-spin > div:nth-child(7) {
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg);
    -webkit-transform-origin: 51.45898036px 64.73288488px;
    transform-origin: 51.45898036px 64.73288488px;
  }
  .lds-spin div:nth-child(8) > div {
    left: 73.45898036px;
    top: 34.93660904px;
    -webkit-animation-delay: -0.22s;
    animation-delay: -0.22s;
  }
  .lds-spin > div:nth-child(8) {
    -webkit-transform: rotate(252deg);
    transform: rotate(252deg);
    -webkit-transform-origin: 81.45898036px 42.93660904px;
    transform-origin: 81.45898036px 42.93660904px;
  }
  .lds-spin div:nth-child(9) > div {
    left: 110.54101964px;
    top: 34.93660904px;
    -webkit-animation-delay: -0.11s;
    animation-delay: -0.11s;
  }
  .lds-spin > div:nth-child(9) {
    -webkit-transform: rotate(288deg);
    transform: rotate(288deg);
    -webkit-transform-origin: 118.54101964px 42.93660904px;
    transform-origin: 118.54101964px 42.93660904px;
  }
  .lds-spin div:nth-child(10) > div {
    left: 140.54101964px;
    top: 56.73288488px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .lds-spin > div:nth-child(10) {
    -webkit-transform: rotate(324deg);
    transform: rotate(324deg);
    -webkit-transform-origin: 148.54101964px 64.73288488px;
    transform-origin: 148.54101964px 64.73288488px;
  }
  .lds-spin div:nth-child(11) > div {
    left: 152px;
    top: 92px;
    -webkit-animation-delay: 0.11s;
    animation-delay: 0.11s;
  }
  .lds-spin > div:nth-child(11) {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 160px 100px;
    transform-origin: 160px 100px;
  }
  .lds-spin {
    width: 30px !important;
    height: 30px !important;
    -webkit-transform: translate(-15px, -15px) scale(0.15) translate(15px, 15px);
    transform: translate(-15px, -15px) scale(0.15) translate(15px, 15px);
  }