.er-l-dlg-body{
    max-height: 400px;
    overflow-y: auto;
    word-break: break-all;
}

.er-l-dlg-lbl-date{
    font-size: 15px;
    color: #1a2a1a;
    font-weight: bold;
}

.er-l-dlg-lbl-type{
    font-size: 14px;
    font-style: italic;
    margin: 5px 0px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #9a9a9a;
}

.er-l-dlg-lbl-msg{
    font-size: 13px;
}