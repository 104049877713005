/* Filter bar */
.filter-box{
    margin-top: 18px;
    margin-bottom: -16px;
}

.filter-box .results-filter button {
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    background: none;
    color: #777777;
    display: block;
    padding: 5px 15px;
    border: 1px solid #DEDEDE;
    background-color: #F7F7F7;
    white-space: nowrap;
}

.filter-box-btn-img{
    width: 10px;
    height: 10px;
    font-size: 10px;
    margin-left: 5px;
}
